<template>
  <div
    v-on-clickaway="away"
    class="search-product"
    :class="{'hide-details': hideDetails}"
  >
    <b-input-validation
      v-model="searchInput"
      :autofocus="autofocus"
      :loading="loading"
      :label="label"
      :size="size"
      autocomplete="off"
      :placeholder="
        placeholder
          ? placeholder
          : type == 'product' || type == 'size'
            ? placeholders.product.name
            : type == 'pack'
              ? placeholders.pack.name
              : placeholders.product.sku
      "

      :disabled="disabled"
      :hide-details="hideDetails"
      :message="message ? message : !sitesIds.length && focused ? $t('please_select_site') : ''"
      :is-error="inputType == 'is-danger'"
      :rounded="rounded"
      @focus="focused = true"
      @blur="focused = false"
    />
    <div
      v-if="showResult"
      class="result"
      :class="type == 'product' ? 'products' : null"
    >
      <template v-if="!results.length">
        <span class="no-result">{{ $t("no_result_found") }}</span>
      </template>
      <template v-for="(item, index) in results">
        <div
          v-if="type == 'product'"
          :key="`product_${item.id}`"
          class="selectable"
          @click="selectItem(item, item.label)"
        >
          {{ item.label }}
        </div>

        <div
          v-else-if="type == 'pack'"
          :key="`pack_${item.id}`"
          class="selectable"
          @click="selectItem(item, item.name)"
        >
          {{ item.name }}
        </div>

        <template v-else>
          <label
            :key="`item_${index}`"
            class="has-text-weight-semibold"
          >{{
            item.label
          }}</label>
          <template v-for="product in item.children">
            <div
              :key="
                `product_${product.product_id}_${product.size_id}_${product.id}`
              "
              class="selectable"
              @click="selectItem(product, item.label)"
            >
              {{
                `${product.size} ${
                  product.flavor ? " - " + product.flavor : ""
                }`
              }}
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway2";
import debounce from "debounce";

export default {
  mixins: [clickaway],
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "sku"
    },
    message: {
      type: [Array,String],
      default: null
    },
    inputType: {
      type: String,
      default: null
    },
    categoryId: {
      type: [Number, String],
      default: null
    },
    brandId: {
      type: [Number, String],
      default: null
    },
    sitesIds: {
      type: [Array, Number, String], //Array for type product and Number/String for type pack
      required: true
    },
    sldId: {
      type: [Number, String],
      default: null
    },
    selectedProd: {
      type: [String, Number],
      default: ""
    },
    exclude: {
      type: Array,
      default: () => []
    },
    // input dense prop
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    avs: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    prodId: {
      type: [Number, String],
      default: null
    },
    langIso: {
      type: [Number, String],
      default: null
    },
    warehouseId: {
      type: [Number, String],
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    rounded: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    pack: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: [Number, String],
      default: null
    },
  },
  data() {
    return {
      searchInput: "",
      showResult: false,
      selected: false,
      focused: false,
      results: [],
      loading: false
    };
  },
  watch: {
    searchInput: "search"
  },
  created() {
    if (this.selectedProd != "") this.selected = true;
    this.searchInput = this.selectedProd;
  },
  methods: {
    search: debounce(function() {
      if (this.selected) {
        this.selected = false;
        return;
      }
      if (this.searchInput === "") {
        this.$emit("selectItem", {});
        this.showResult = false;
        return;
      }
      this.loading = true;
      if(this.type == 'pack') {
        let filter = `?filters=${btoa(
        JSON.stringify([
          { id: "name", selected: this.searchInput, operator: "contains" },
          { id: "site_id", selected: this.sitesIds, operator: "is" },
          { id: "lang_iso", selected: this.langIso, operator: "is" },
        ])
      )}`;

        this.$axios
        .get("packs" + filter)
        .then(({ data }) => {
            this.results = data.list;
            this.showResult = true;
        })
        .catch(e => {
          this.clientError(e);
        })
        .finally(() => (this.loading = false));
      } else {
      this.$axios
        .post("catalog/product/search", {
          search: this.searchInput,
          sites_ids: this.sitesIds,
          sld_id: this.sldId,
          avs: this.avs ? 1 : 0,
          prod_id: this.prodId,
          warehouse_id: this.warehouseId,
          category_id: this.categoryId,
          brand_id: this.brandId,
          pack: this.pack,
          order_id: this.orderId,
        })
        .then(({ data }) => {
          if (this.type == "sku") {
            this.handleShowSku(data);
          } else if (this.type == "size") {
            this.handleShowProducts(data);
          } else {
            this.results = data;
            this.showResult = true;
          }
        })
        .catch(e => {
          this.clientError(e);
        })
        .finally(() => (this.loading = false));
      }
    }, 500),
    handleShowSku(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        const product = data[i];
        let obj = {
          label: product.label,
          children: []
        };
        for (let j = 0; j < product.sizes.length; j++) {
          const size = product.sizes[j];
          for (let k = 0; k < size.items.length; k++) {
            const item = size.items[k];
            if (this.exclude.findIndex(id => id == item.id) == -1) {
              obj.children.push({
                label: product.label,
                size: size.label,
                id: item.id,
                flavor: item.label,
                product_id: product.id,
                size_id: size.id
              });
            }
          }
        }
        if (obj.children.length > 0) arr.push(obj);
      }
      this.results = [];
      this.results = arr;
      this.showResult = true;
    },
    handleShowProducts(data) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        const product = data[i];
        let obj = {
          label: product.label,
          children: [
            {
              size: this.$tc("all_size", 2),
              id: product.id,
              product_id: product.id,
              size_id: null
            }
          ]
        };
        for (let j = 0; j < product.sizes.length; j++) {
          const size = product.sizes[j];
          obj.children.push({
            size: size.label,
            id: size.id,
            product_id: product.id,
            size_id: size.id
          });
        }
        arr.push(obj);
      }
      this.results = arr;
      this.showResult = true;
    },
    selectItem(product, itemName) {
      if (this.type == "sku") {
        product.label = itemName + " - " + product.size + (product.flavor ? ` - ${product.flavor}` : ``);
      } else if (this.type == "size") {
        product.label = `${itemName} - ${product.size}`;
      } else if( this.type == "pack") {
        product.label = product.name;
      }

      this.$emit("selectItem", product);
      if (this.clearable) {
        this.searchInput = null;
      } else {
        this.searchInput = product.label;
      }
      this.selected = true;
      this.showResult = false;
    },
    away() {
      this.showResult = false;
    }
  }
};
</script>
